<template>
    <div class="app-main__inner">
        <div class="container mb-3">
            <div class="row">
                <div class="col-md-12 p-2 bg-white rounded"> <!--pl-5 pr-5-->
                    <div class="mb-5 mt-3">
                        <h2 class="page-title">{{ $t('bHistory') }}</h2>
                        <table v-loading='loading' style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    <!-- <th class="text-xs">{{ $t('compName') }}</th> -->
                                    <th class="text-xs"><div class="d-flex justify-content-between">{{ $t('amtDue') }}<span><i class="lnr-arrow-up cursor-pointer"></i><i   class="lnr-arrow-down cursor-pointer"></i></span></div></th>
                                    <th class="text-xs"><div class="d-flex justify-content-between">{{ $t('date') }}<span><i   class="lnr-arrow-up cursor-pointer"></i><i   class="lnr-arrow-down cursor-pointer"></i></span></div></th>
                                    <th class="text-xs"><div class="d-flex justify-content-between">{{ $t('due')}}<span><i   class="lnr-arrow-up cursor-pointer"></i><i   class="lnr-arrow-down cursor-pointer"></i></span></div></th>
                                    <th class="text-xs">{{ $t('status') }}</th>
                                    <th class="text-xs">{{ $t('file') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(invoice, i) in billingData" :key="i">
                                    <td class="text-xs">{{ invoice.CustomerName }}</td>
                                    <td class="text-xs">{{ invoice.Total }} SEK</td>
                                    <td class="text-xs">{{ invoice.InvoiceDate }}</td>
                                    <td class="text-xs">{{ invoice.DueDate }}</td>
                                    <td class="text-xs">
                                        <span v-if="invoice.Balance === 0">Paid</span>
                                        <span v-else>Unpaid</span>
                                    </td>
                                    <td>
                                        <DownloadTableManager :data='invoice' />
                                    </td>
                                </tr>
                                <!-- <tr v-if="history.Invoices !== undefined && history.Invoices.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
                            </tbody>
                        </table>
                        <div class="float-right mb-5" v-if="history.MetaInformation">
                            <paginate
                            :page-count="history.MetaInformation['@TotalPages']"
                            :click-handler="changePageNumber"
                            prev-text="<"
                            next-text=">"
                            :container-class="'pagination'">
                            </paginate>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-1"></div> -->
            </div>
        </div>


        <!-- <el-dialog
        :visible.sync="paymentCardModal"
        width="40%"
        :handleClose="handleClose"
        center>
        <div class="container">
            <div class="row">
                <div id="card-element" class="col-md-12">

                </div>
            </div>
        </div>
        </el-dialog> -->

        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Paginate from 'vuejs-paginate'
    // import i18n from './../../plugins/i18n'
    import DownloadTableManager from './../../customComponent/DownloadTableManager'

    export default {
        name : 'billing-dashboard',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert'),
            paginate : Paginate,
            DownloadTableManager
        },
        data () {
            return {
                PaymentType : null,
                billing : {
                    name : '',
                    company_id : '',
                    address : {
                        address_line_one : '',
                        zip_code : '',
                        city : '',
                        phone_mobile : '',
                    },
                    email : '',
                    invoiceEmail : ''
                },
                billingData : [],
                history : [],
                copyHistory : [],
                updating : false,
                paymentCardModal : false,
                processingCard : false,
                processingInvoice : false,
                loading : false
            }
        },
        computed : {
            ...mapGetters({

            })
        },
        mounted () {

        },
        methods : {
            getBilling  () {
                this.loading = true
                this.$services.axios.defaults.headers.common.Authorization = `Token ${this.USER_TOKEN}`
                this.$services.axios.get(this.$services.endpoints.ARTICLES_ENDPOINT)
                .then(res => {
                    this.loading = false
                    if (res.status === 200) {
                        this.billingData = res.data
                    }
                }).catch((err) => {
                    this.loading = false
                    // this.loadingSub = false
          
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.custom-btn-pay{
    background-color: #F167CA !important;
    border-color: #F167CA !important;
    font-weight: 800;
}
.custom-card-section{
    box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03),0 0.9375rem 1.40625rem rgba(4,9,20,0.03),0 0.25rem 0.53125rem rgba(4,9,20,0.05),0 0.125rem 0.1875rem rgba(4,9,20,0.03);
    border-width: 0;
    transition: all .2s;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(26,54,126,0.125);
}
.custom-save-btn{
    background-color: #2CDCA7 !important;
    border-color: #2CDCA7 !important;
    font-weight: 800;
}
.override-form-field{
    border: 0;
    border-bottom: 2px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: #fff !important;
    border-radius: unset !important;
}
.col-form-label {
    padding-top: 1rem !important;
    // font-size: 14px !important;
}
.form-group{
    margin-bottom: 0.4rem !important;
}
.form-input{
    font-size: 0.8rem !important;
    padding: 0.2rem 0.75rem !important;
}
.col-form-label{
    padding-bottom: unset !important;
    padding-top: 0.3rem !important;
}
.app-main .app-main__inner{
    padding-top: 20px !important;
}
.table td{
    padding: 0.1rem 0.55rem !important;
}
.form-control{
    border: 1px solid $form-border !important;
    height: unset !important;
    padding: .175rem .75rem !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
.btn-border-all{
    border: 1px solid $theme-secondary-color !important;
    color: $theme-secondary-color !important;
}
</style>
